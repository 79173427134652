import React from 'react'
import './Contact.css'

const Contact = () => {
    return (
        <div className='Contact'>
            <div className="up_contact">
                <div>
                    <p>Phone</p>
                    <a href="tel:+38349719152">(+44)123123123</a>
                </div>
                <div>
                    <p>Email</p>
                    <a href="mailto:mulajgent2001@gmail.com">test@gmail.com</a>
                </div>
            </div>

            <form action="mailto:mulajgent2001@gmail.com" method="POST">
                <label htmlFor="username">Username:</label>
                <input type="text" id="username" name="username" required/>
                
                <label htmlFor="email">Email address:</label>
                <input type="email" id="email" name="email" required/>

                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" required></textarea>

                <input type="submit" value="Submit"/>
            </form>
        </div>

    )
}

export default Contact