import React, { useEffect, useState } from "react";
import './Product.css'

import data from './data'
import { useParams } from "react-router-dom";


const ImageSlider = () => {

    // autochange
    // const [currentSlide, setCurrentSlide] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentSlide((currentSlide + 1) % item.images.length);
    //     }, 3000); // Change the slide every 5 seconds
    //     return () => clearInterval(interval);
    // }, [currentSlide]);

    const { id } = useParams(); 
    const item = data.find(
        item => item.id === parseInt(id)
    ); 



    const [indexes, setIndexes] = useState({});

    const nextSlide = (items) => {
        const curIndex = indexes[items.id] || 0;
        const newIndex = curIndex === items.images.length - 1 ? 0 : curIndex + 1;
        setIndexes({ ...indexes, [items.id]: newIndex });
    };
    const prevSlide = (items) => {
        const curIndex = indexes[items.id] || 0;
        const newIndex = curIndex === 0 ? items.images.length - 1 : curIndex - 1;
        setIndexes({ ...indexes, [items.id]: newIndex });
    };



    return (
        <div className="div_slider">
            {/* <div className="div_slider">
                <img src={item.images[currentSlide]} alt={`Slide ${currentSlide}`} />
            </div> */}

                <div key={item.id} className="image_slider">


                    <img src={(item.images[indexes[item.id] || 0])} alt="" />
                </div>
                <div className="btn_slider">
                    <button onClick={() => prevSlide(item)}> &#8592; </button>
                    <button onClick={() => nextSlide(item)}> &#8594; </button>
                </div>


        </div>
    );
};

export default ImageSlider;
