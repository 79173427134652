import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer className='Footer'>
            <p>&copy; 2023 Gent Mulaj. All rights reserved.</p>
        </footer>
    )
}

export default Footer