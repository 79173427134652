import Footer from './components/Footer';
import Header from './components/Header';
import About from './pages/About';
import Contact from './pages/Contact';
import LatestProduct from './pages/LatestProduct'

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Sculpture from './pages/Sculpture';
import Product from './pages/Product';

import data from './pages/data'
import ImageSlider from './pages/ImageSlider';

function App() {
  return (
        <>
        <Header/>
          <Routes>
            <Route path="/"  element={<Home/>} />
            <Route path="sculpture"  element={<Sculpture/>} />
            <Route exact path="/sculpture/:id" element={<Product data={data} />}/>

            <Route path="/about"  element={<About/>} />
            <Route path="/contact"  element={<Contact/>} />
          </Routes>
          <Footer/>
          </>


  );
}

export default App;