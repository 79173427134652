const new_data = [
    {
        id: 1,
        name: "Sculpture Name 1",
        description: "A beautiful sculpture made of marble.",
        price: 32.14,
        images: [
            "https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg",
            "https://onlineimagetools.com/images/examples-onlineimagetools/color-grid.png",
        ],
    },



    {
        id: 2,
        name: "Sculpture Name 2",
        description: "A beautiful sculpture made of marble.",
        price: 1412.14,
        images: [
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfD0Tka3uvKNdwTU3WPj0rRD4atghAI4EUlO1sE2pIFNLGEG9GG-eTSw1AcDO46l0y_2E&usqp=CAU",
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVH7nu_MOD-E8PMuTkhSsAendZBD9QmLtQU13RI0dCfbn6oBQPE0oGi-dj6XSKqmNw-NM&usqp=CAU",
        ],
    },


    {
        id: 3,
        name: "Sculpture Name 3",
        description: "A beautiful sculpture made of marble.",
        price: 3212.14,
        images: [
            "https://www.liveabout.com/thmb/7sipcs3AUB_OvuHfCStynK9Pph4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Dice_by_Ilona_Nagy_GettyImages-629242270-5bcf6a5546e0fb0051f108ea.jpg",
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr0ndu_tSatLeJOHqQg_5ukCq2JwiQ5U_YUif86SikwSgdqDxcSJDWIUY-UignFlJqZP0&usqp=CAU",
        ],
    },



    

        // // Add more sculptures as necessary
    ];
    
    export default new_data;
    