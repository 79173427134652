import React from 'react'
import { Link } from 'react-router-dom'
import new_data from './new_data'
import './LatestProduct.css'

const LatestProduct = () => {
    
    return (
        <div className="Sculpture LatestProduct">
            <div className="sculpture__cards latest_cards">
                {
                    new_data.map(item => (
                        <Link to={`/sculpture/${item.id}`} >
                            <div className="card latest_card">
                                <img src={item.images[0]} alt="" />
                                <p>{item.name}</p>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>    

    )
}

export default LatestProduct