import React from 'react';
import './About.css';

function About() {
    return (
        <div className='About'>
            <div className="about-us">
                <h2>About Us</h2>
                <p>Hi, my name is Dan Mulaj and I am a Artists / Sculptor .</p>
                <p>[Add a brief description about yourself here]</p>
            </div>
        </div>
    );
}

export default About;
