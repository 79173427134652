import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'

function Header() {
    const [showMenu, setShowMenu] = useState(false);

    function toggleMenu() {
        setShowMenu(!showMenu);
    }

    function closeMenu() {
        setShowMenu(false);
    }

    return (
        <header>
        <nav className='container'>
            <div className="logo">
                <Link to="/">DAN MULAJ</Link>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={`menu ${showMenu ? "show" : ""}`}>
                <li><Link to="/" onClick={closeMenu}>HOME</Link></li>
                <li><Link to="/sculpture" onClick={closeMenu}>SCULPTURE</Link></li>
                <li><Link to="/about" onClick={closeMenu}>ABOUT</Link></li>
                <li><Link to="/contact" onClick={closeMenu}>CONTACT</Link></li>
                <li className="close" onClick={closeMenu}><button>X</button></li>
            </ul>
        </nav>
        </header>
    );
}

export default Header;
