import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import LatestProduct from './LatestProduct';

function Home() {
    return (
        <div className="Home">
            <div className="home-container">
                <h1>Welcome to My Website</h1>
                <p>Thank you for visiting my website.</p>
                <p>Please check out my work and contact me if you have any questions.</p>
                
                <Link to="/contact"><button>Contact Me</button></Link>
            </div>

            <div className="best__product">
                <h1>Latest Product</h1>
                <LatestProduct/>
            </div>
        </div>
    );
}

export default Home;
