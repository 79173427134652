import React from "react";
import {  useParams } from "react-router-dom";
import sculptures from "./data";
import ImageSlider from "./ImageSlider";
import './Product.css'

function Product() {

    const { id } = useParams(); 
    const item = sculptures.find(
        item => item.id === parseInt(id)
    ); 




    return (
        <div className='Product'>

        <div className="product__left">
            <ImageSlider />


        </div>

        <div className="product__right">
            <div className="product__up">
                <table>
                    <tr>
                        <td>{item.name}</td>
                    </tr>
                    <tr>
                        <td>{item.description}</td>
                    </tr>
                    <tr>
                        <td>{item.kg}<b> KG</b></td> 
                    </tr>
                    <tr>
                        <td><span>Dimension : </span>{item.dimensionX} x {item.dimensionY} CM</td>
                    </tr>
                </table>
            </div>

            <div className="product__down">
                <h2>{item.price}&euro;</h2>
                <button></button>
            </div>
        </div>




        </div>
    );
}

export default Product;


