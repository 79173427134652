import React from "react";
import { Link } from "react-router-dom";
import sculptures from "./data";
import './Sculpture.css'

function Sculpture() {
    return (
        <div className="Sculpture">
            <div className="sculpture__cards">
                {
                    sculptures.map(item => (
                        <Link to={`/sculpture/${item.id}`} >
                            <div className="card">
                                <img src={item.images[0]} alt="" />
                                <p>{item.name}</p>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    );
}

export default Sculpture;
